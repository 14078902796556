import {Link, useLocation} from "react-router-dom";
import ticketBoat from "../../assets/ticketboat.png";
import {Button} from "@nextui-org/react";
import React, {useEffect, useState} from "react";

function UnauthenticatedContent({onLogin}) {
    const scopes = ["openid", "profile", "roles"];
    const [verifyMessage, setVerifyMessage] = useState("")
    const location = useLocation()
    const appUrl = process.env.REACT_APP_ALLOWED_CALL_BACK_URLS;

    useEffect(() => {
        let encodedString = location.search
        if (!encodedString || !encodedString.includes("error_description")) {
            setVerifyMessage("")
            return
        }
        // Split the string by '&' to get individual key-value pairs
        const keyValuePairs = encodedString.split('&');
        // Initialize an empty object to store key-value pairs
        const decodedData = {};
        keyValuePairs.forEach((pair) => {
            const [key, value] = pair.split('=');
            decodedData[key] = decodeURIComponent(value);
        });
        const {error_description: message} = decodedData;
        setVerifyMessage(message)
    }, [])

    async function login() {
        let currentDate = new Date();
        let date = currentDate.setDate(currentDate.getDate() + 30);
        await onLogin({scope: scopes.join(" "), appUrl})
        await localStorage.setItem("lastDayLoggedIn", date)
    }


    return (
        <div className="home">
            <Link className="img" to="/">
                <img src={ticketBoat} alt="Logo" className="w-1/2"/>
            </Link>
            <Button
                variant="ghost"
                className="text-white w-[150px] font-bold mt-5 hover:text-black"
                onClick={login}
            >
                Sign In
            </Button>
            {/* <h3 className={"text-white text-center w-[400px] font-bold mt-6"} >
                Access is currently unavailable while we undergo maintenance.  Please reach out to your account representative if you have questions
            </h3> */}
            {verifyMessage && <h3 className={"text-white text-center w-[400px] font-bold mt-6"}>{verifyMessage}</h3>}
        </div>
    );
}

export default UnauthenticatedContent